.coupon-v1 {

	padding: 20px 20px;
	border: 1px solid var(--color-grey-normal);
	background-color: var(--color-white);

	.coupon-field {
		margin-top: 20px;

		.form-control {

			margin-right: 15px;
		}
	}
}