.facility-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.region-name {
		margin-top: -20px;
		color: var(--color-grey-dark);
	}

	.facility-map {
		width: 100%;
		height: var(--facility-map-height);
		position: relative;
		border-radius: 5px;
	}

	.unit-description-v2 {
		.description {
			line-height: 1rem;
		}
	}

	.facility-sidebar {
		background-color: var(--color-white);
		padding: 5px;
		border: 1px solid var(--color-grey-normal);

		.facility-bar {
			background-color: var(--widget-header-bg-color);
			padding: 10px 15px;
		}

		ul {
			list-style: none;
			padding-left: 30px;
			padding-top: 10px;

			li {
				list-style: disc;
				padding: 5px 0;

				a {
					color: var(--font-color-main);
				}
			}
		}
	}
}